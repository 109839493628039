<template>
  <div class="auth-wrapper auth-v1">
    <b-row class="auth-inner m-0">

      <!-- Reset password-->
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div
            style="text-align:center; margin-top:-50px;"
          >
            <b-img
              style="width:150px;"
              :src="require(`@/assets/images/partner/${partner}/logo/logo.png`)"
            />
            <br><br><br><br>
          </div>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Kontoeinrichtung
          </b-card-title>
          <div
            v-if="showSpinner"
          >
            <b-spinner
              small
              label="Wir bereiten Ihr Konto vor..."
            />Wir bereiten Ihr Konto vor...
          </div>
          <b-alert
            v-if="!showSpinner"
            :variant="verifyStatus"
            show
          >
            <h4 class="alert-heading">
              {{ verifyStatusTitle }}
            </h4>
            <div class="alert-body">
              <span>{{ verifyStatusText }}</span>
            </div>
          </b-alert>

          <p class="text-left mt-2">
            <b-link
              :to="{path:`/login/base/${token}`}"
              :style="getHrefStyle()"
            >
              <feather-icon icon="ChevronLeftIcon" /> zur Anmeldung
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
//  import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BCardTitle, BImg, BLink, BSpinner, BAlert,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import axios from '@axios'
import router from '@/router'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BImg,
    BLink,
    BSpinner,
    BAlert,
    // BCardText,
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  setup() {
    const partner = ref(window.$cookies.get('partner'))
    const partnerData = ref(window.$cookies.get('partnerData'))
    const getHrefStyle = () => `color:var(--${partnerData.value.colorScheme})`
    document.title = partnerData.value.htmlPageTitle
    document.querySelector("link[rel*='icon']").href = `favicon_${partnerData.value.key}.ico`

    // fallback
    if (partnerData.value === null) {
      router.push('/partner/ladestellenbonus/login')
    }

    const verifyStatusTitle = ref('')
    const verifyStatusText = ref('')
    const verifyStatus = ref('')
    const showSpinner = ref(false)
    const token = ref('')

    axios
      .get(`verify/vin/${router.currentRoute.params.token}`)
      .then(response => {
        console.log(response.status)
        if (response.status === 200) {
          verifyStatusText.value = 'Ihr Konto ist validiert, Sie können sich anmelden.'
          verifyStatusTitle.value = 'Erfolg'
          verifyStatus.value = partnerData.value.colorScheme
          token.value = response.data
        } else {
          verifyStatusText.value = 'Fehler beim Validieren Ihres Sicherheitstokens!'
          verifyStatusTitle.value = 'Fehler'
          verifyStatus.value = 'danger'
        }
      })
      .catch(() => {
        verifyStatusText.value = 'Fehler beim Validieren Ihres Sicherheitstokens!'
        verifyStatusTitle.value = 'Fehler'
        verifyStatus.value = 'danger'
      })

    return {
      partner,
      verifyStatusTitle,
      verifyStatusText,
      verifyStatus,
      showSpinner,
      partnerData,
      getHrefStyle,
      token,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
